#HomePageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

#WelcomeMessage {
  position: relative;
  padding-top: 10vh;
  font-style: normal;
  font-weight: normal;
  font-size: calc(100% + 1.8vw);
  line-height: 8vh;
  text-align: center;
  color: #000000;
}

.HomepageButton {
  position: relative;
  max-width: 400px;
  width: 80vw;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 64px; /*Stops footer from overlapping*/
}

.ButtonList {
  padding-top: 10vh;
  max-width: 400px;
  position: relative;
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 5vh; /*Stops footer from overlapping*/
}

@media only screen and (max-height: 800px) {
  #WelcomeMessage {
    padding-top: 3vh !important;
  }

  .HomepageButton {
    margin-bottom: 2vh; /*Stops footer from overlapping*/
    font-size: 1rem;
  }
}
