.radioWrapper {
  padding-left: 15px;
}

.formLabelText .btn {
  padding: 1px 5px 5px 5px;
  margin-bottom: 4px;
}

.SpaceLine {
  line-height: 1;
}

.formEntrySpecificToLocation,
.formEntrySpecificFromLocation {
  width: 200px;
  font-size: 1rem !important;
}
