.inline-text {
  display: flex;
}
.large-checkbox {
  margin-left: 90px;
}
.large-checkbox .form-check-input {
  transform: scale(3, 3) !important;
}

@media only screen and (max-width: 700px) {
  .large-checkbox {
    margin-left: 12vw;
  }
  .large-checkbox .form-check-input {
    transform: scale(2, 2) !important;
  }
}
.inline {
  display: flex;
  margin-left: 0px;
}

.barcodeScannerButton {
  padding-top: 0px;
  padding-bottom: 0px;
}

.successButton.barcodeScannerButton {
  color: #28a745;
  border-color: #28a745;
}

.radioWrapper {
  padding-left: 15px;
}

.availableCheckBox {
  margin-top: 0.5vw;
}

.availableRadioButton {
  margin-top: 0px;
  margin-bottom: 0px;
}
.formLabelText {
  column-width: auto;
  margin-left: 0px !important;
  margin-bottom: 0px;
  margin-top: 0px;
}
.formPlateLabelText {
  width: auto;
}
.formPlateEntry {
  width: 150px;
  font-size: 1rem !important;
}
.formReasonForNoPlateLabelText {
  column-width: auto;
}
.formReasonForNoPlate {
  width: 250px;
  font-size: 1rem !important;
}
.formReasonForNoVIN {
  width: auto;
  font-size: 1rem !important;
}

.formVINLabelText {
  column-width: auto;
}

.formVINEntry {
  width: 225px;
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 1rem !important;
}
.formNoVINReasonDetails {
  width: 275px;
  font-size: 1rem !important;
}
.shortRow {
  height: 15px;
}

.radioGroupRow {
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: -0px;
}

.fullScreenModal .modal-dialog {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.fullScreenModal .modal-content {
  height: 100vh;
}
