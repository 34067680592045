.allowLinebreaks {
  white-space: pre-wrap;
}

textarea {
  width: 100%;
}

.left {
  margin-left: 0px;
  width: 100%;
}

.confirmedModal {
  border-color: #99ad8c;
  border-width: 2px;
  font-size: min(4vw, 20px) !important;
}

.deleteModal .modal-content {
  border-color: #aa7e7e;
  border-width: 2px;
  font-size: min(4vw, 20px) !important;
  white-space: pre-line;
}

.modal-content .ModalConfirmation {
  background-color: #ceeabd;
  border-color: #99ad8c;
  border-width: 2px;
  color: #30362c;
  font-size: min(4vw, 20px) !important;
}

.ModalYesNoConfirmation {
  font-size: min(5vw, 20px) !important;
}

.modal-content h6 {
  font-size: min(4vw, 20px) !important;
}

.modal-content h5 {
  font-size: min(5vw, 25px) !important;
}

.modal-content h4,
.modal-title {
  font-size: min(6vw, 25px) !important;
}

.modal-content .form-control {
  font-size: min(5vw, 25px) !important;
}

.RateSheetLink {
  text-decoration: underline;
}
.RemoveInventoryBody {
  font-size: large !important;
}

.RemoveInventoryVehicle {
  font-size: large !important;
  font-weight: bold !important;
}
.RemoveInventoryVehicle span {
  font-size: large !important;
  font-weight: normal !important;
}
.RemoveInventoryBodyBold {
  font-size: large !important;
  font-weight: bold !important;
}

.modalSubsequent .modal-dialog {
  font-size: 100%;
  max-width: 80vw;
}

.modal-dialog .btn {
  font-size: min(4vw, 20px);
}

@media only screen and (max-width: 768px) {
  .modalSubsequent .modal-dialog {
    font-size: 70%;
    max-width: 500px;
  }
  .ModalConfirmation {
    padding-right: 0px !important;
  }
}
