.paid {
	background-color: var(--paid-light-color) !important;
}

.submitted,
.resubmitted {
	background-color: var(--submitted-light-color) !important;
}

.draft {
	background-color: var(--draft-light-color) !important;
}

.actionrequired {
	background-color: var(--actionRequired-light-color) !important;
}
.deleted {
	background-color: var(--deleted-light-color) !important;
}
.submitted,
.resubmitted {
	background-color: var(--submitted-light-color) !important;
}

.table-sm .submitted,
.table-sm .resubmitted {
	background-color: white !important;
}

#mobileEllipsesTableDropdown {
	width: 100%;
	border: none;
	background: none;
	background-color: none;
	font-weight: bolder;
	font-size: 130%;
	padding: 4px 6px 4px 6px;
}

.dropdownCell .dropdown-toggle::after {
	content: none !important;
}

.dropDownHeader {
	width: 50px;
}
.createButtonDiv {
	text-align: right;
}
