.react-date-picker__wrapper {
	display: flex;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 70%;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #343a40;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	flex-grow: 1;
	flex-shrink: 0;
}
.react-calendar {
	width: 350px;
	max-width: 100%;
	background: white;
	border: 1px solid #a0a096;
	border-radius: 3%;
	font-family: Arial, Helvetica, sans-serif;
	line-height: 1.125em;
	/* position: relative; */
}
.react-calendar__navigation {
	height: 44px;
	margin-bottom: 0px !important;
	background: #76baff78;
}
.react-date-picker__inputGroup {
	min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
	flex-grow: 1;
	padding: 0 2px;
	box-sizing: content-box;
	background-color: white;
}
.react-date-picker__button {
	border: 0;
	background: white;
	padding: 4px 6px;
}

.react-date-picker__calendar {
	width: 350px;
	max-width: 100vw;
	position: relative !important;
	top: 100%;
	left: 0;
	z-index: 1;
}
.react-date-picker {
	display: block;
	position: relative;
}
.react-daterange-picker {
	display: block !important;
	position: relative;
}
.react-date-picker.react-date-picker--open.react-date-picker--enabled {
	height: 0px;
}
.react-daterange-picker__calendar.react-daterange-picker__calendar--open {
	height: 0px !important;
	margin-top: 8%;
}
.react-daterange-picker__calendar {
	width: 350px;
	max-width: 100vw;
	position: relative !important;
	top: 100%;
	left: 0;
	z-index: 1;
}
.react-daterange-picker__wrapper {
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;

	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 70%;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #343a40;
	border-radius: 0.25rem;
	align-items: center;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

span.react-daterange-picker__range-divider {
	padding-right: 10%;
}

button.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day {
	border-bottom-right-radius: 50%;
	border-top-right-radius: 50%;
}
button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day {
	border-bottom-left-radius: 50%;
	border-top-left-radius: 50%;
}
button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
	border-radius: 50%;
}
.react-calendar__tile--active {
	background: #268bf3;
	color: white;
}
