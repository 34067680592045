.lineItemUnit {
	width: 3em;
	height: 1.5em;
	display: inline-block;
}

.lineItemUnitFlat {
	width: 3em;
	height: 1.5em;
	border: none;
	background: transparent !important;
}

.lineItemLabel {
	padding-left: 6px;
}

.right {
	text-align: right;
}

#LineItemDetails td,
#LineItemDetails th,
#LineItemDetails tr {
	padding: 0;
	background: none;
	border: none;
	width: 100%;
}
.LineItemMobileTable {
	padding: 0;
	background: none;
	border: none;
	width: 90%;
}
