.subsequentTowMessageTitle {
  text-align: center;
  padding-bottom: 1vh;
  font-size: 120%;
}

.subsequentTowMessageBody {
  font-size: 80%;
}

#claimNumber {
  margin-bottom: 1vh;
  max-width: 300px;
  margin: 0 auto;
}

#subsequentTowInfoWrapper .summaryField {
  padding-left: 10px;
  max-width: 24%;
}

#subsequentTowInfoWrapper .summaryField,
#subsequentTowInfoWrapper .summaryLabel {
  font-size: 90%;
}

#subsequentTowInfoWrapper {
  font-size: 85%;
  padding: 10px;
}

#claimNumberMessage {
  max-width: 700px;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  #subsequentTowInfoWrapper .summaryField {
    max-width: 48% !important;
  }
  #subsequentTowInfoWrapper {
    font-size: calc(35% + 1vw) !important;
    padding: 10px !important;
  }
}

.react-date-picker {
  display: block;
  position: relative;
}
