.lineItemUnit,
.lineItemUnitFlat {
  font-size: calc(12px + 1vw);
}

.inputQuantity input {
  font-size: 100% !important;
  width: 30%;
}

.switchSelector {
  height: 45px;
}
@media only screen and (max-width: 760px) {
  #LineItemTable td {
    border: none;
    position: relative;
    padding-left: 5%;
    white-space: normal;
    text-align: left;
    font-size: calc(12px + 1vw);
  }
  #LineItemTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #LineItemTable td > tr:nth-of-type(odd) {
    background: none;
  }
  #LineItemTable input {
    font-size: 100% !important;
    padding-top: 0%;
    padding-bottom: 0%;
  }
  .invoiceLinesContainer #alertError,
  .popover-body {
    font-size: calc(12px + 1vw);
  }
}
