.invoiceSubmissionHeader {
  text-align: center;
  font-size: 45px;
  width: 100%;
  padding-bottom: 25px;
}

.invoiceDetailsContainer .col {
  min-width: 200px;
}

.maxWidth600 {
  max-width: 600px;
  text-align: right !important;
}

.maxWidth400 {
  max-width: 400px;
}

.maxWidth300 {
  max-width: 300px;
}

.maxWidth200 {
  max-width: 200px;
}

.flexRight {
  flex-direction: row-reverse;
}

.alignRight {
  text-align: right;
}

.flexEnd {
  justify-content: flex-end;
  flex-wrap: wrap;
}

.flexBasis100 {
  flex-basis: 100%;
}

.requiredFieldNotice {
  text-align: right;
  font-style: italic;
  width: 100%;
}

.formLabelText .form-check-label {
  padding-top: 5% !important;
}

.formLabelText .form-check {
  padding-top: 10px !important;
}

.formLabelText .form-check-input {
  margin-left: -25px !important;
}

.formLabelText,
.alert {
  font-size: min(4vw, 25px);
  margin-bottom: 2vh;
}

.invoiceDetailsContainer .form-control {
  font-size: 70%;
}

.invoiceDetailsContainer {
  padding: 5vh 2% 5vh 2%;
}

.weightSelector {
  padding-left: 2vh;
}
.invoiceDetailsContainer .form-group {
  margin-bottom: 4vh;
}

.InvoiceTotal {
  text-align: right !important;
  padding-right: 15px;
}

.separator {
  margin: 5vh 0 5vh 0;
  width: 97%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 700px) {
  .invoiceDetailsContainer {
    padding: 2vh 2% 5vh 2%;
  }

  .invoiceDetailsContainer .form-group {
    margin-bottom: 1vh;
  }
  .formLabelText,
  .alert {
    margin-bottom: 0.5vh;
  }

  .alert,
  .alertContainer {
    padding: 0.5vw;
    font-size: 3.5vw;
  }

  .formLabelText .form-check-input {
    margin-top: max(2vw, 6px) !important;
    height: 12px;
  }
  .invoiceDetailsContainer .btn {
    font-size: calc(12px + 1vw);
  }

  .InvoiceTotal {
    font-size: calc(12px + 1.5vw);
  }

  .invoiceDetailsContainer .h5 {
    font-size: calc(100%+2.3vw) !important;
  }

  .separator {
    margin: 3vh 0 3vh 0;
  }

  .invoiceSubmissionHeader {
    font-size: 25px;
    padding-bottom: 10px;
  }
}
