.inventoryListHeading {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;
  max-width: none;
}

.inventoryLocation {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  max-width: none;
}

.h1InventoryList {
  font-size: calc(20% + 3vw);
}
.h2InventoryList {
  font-size: 3vw;
}
.InventoryListAddress {
  font-size: calc(20% + 2vw);
  margin: 0px !important;
}

.buttonScrollToTop {
  position: fixed;
  right: 50%;
  bottom: 10%;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: green !important;
  color: rgb(255, 255, 255);
  border: none;
  outline: none;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.4s;
  animation: fadeIn 0.3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
