.vendorPhone {
  width: 25%;
}
.vendorPayee {
  width: 30%;
}

.vendorCompanyName {
  width: 100%;
}

.vendorTelephone {
  width: 25%;
}

.vendorEmail {
  width: 50%;
}

.compoundAddress {
  width: 60%;
}

.vendorAddress {
  width: 50%;
}

.right {
  text-align: right;
}

.inline {
  display: flex;
  margin-left: 0px;
}
.vendorDetailsHeader {
  text-align: center;
  width: 100%;
}
