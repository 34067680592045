.loader {
  position: fixed;
  top: 50%;
  left: 50%;
}

.loaderComponent {
  position: relative;
  left: 50%;
}
