.paidCell {
	background-color: var(--paid-light-color) !important;
}

.submittedCell,
.resubmittedCell {
	background-color: var(--submitted-light-color) !important;
}

.draftCell {
	background-color: var(--draft-light-color) !important;
}

.actionrequiredCell {
	background-color: var(--actionRequired-light-color) !important;
}
.deletedCell {
	background-color: var(--deleted-light-color) !important;
}
.CompleteCell {
	font-size: min(100%, 15px);
	font-weight: bold;
	border-radius: 5px;
	padding: 2px 4px 2px 4px;
}

.center {
	text-align: center;
}
