.invoiceSummaryHeader {
  text-align: center;
  width: 100%;
}
.invoiceSummaryContainer {
  padding: 2% 0 10% 0; /*  */
  font-size: 1.2rem;
  max-width: 1140px;
}

.buttonsDiv {
  margin-left: 20px;
  width: 100%;
  text-align: center;
}

.buttonDiv {
  display: inline-block;
  text-align: right;
  margin-right: 2%;
}

.invoiceSummaryRow,
.invoiceSaveSubmitRow {
  margin-right: 0;
}
.deletionDisplayContainer {
  margin-top: -3vh;
  max-width: none;
}

@media print {
  .invoiceSummaryButtonRow {
    display: none;
  }
  #header-container {
    display: none;
  }
  #footer-container {
    display: none;
  }
}
