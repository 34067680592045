img {
  max-width: 150px;
  height: 5vh;
  width: auto;
}

.StatusAlert {
  margin-bottom: 0px;
  text-align: center;
  font-weight: 600;
}

.headerNavBar {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  max-width: none;
  flex-wrap: wrap;
}

.dropdown-item {
  font-size: 30px;
  padding: 0.5vh 2vw 0.5vh 2vw !important;
}
.dropdown-menu {
  bottom: auto !important;
}
.navLinkCreateButton {
  padding: 0 10px 0 0 !important;
}

.dropdown-menu {
  min-width: 100px !important;
}

.spinner {
  align-content: center;
  max-height: 3vh;
  max-width: 3vh;
}

.navbar-light .navbar-nav .nav-link .navbar-text {
  color: rgba(0, 0, 0, 0.3) !important;
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.8) !important;
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 1) !important;
  border-color: rgba(0, 0, 0, 1) !important;
}
.createButton {
  font-weight: normal !important;
}

.headerLoginDropDown::after {
  content: none !important;
}
.UserProfileIcon {
  font-weight: 600;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2.5rem;
  background-color: #ced4da;
  background-size: cover;
  font-family: Open Sans;
  font-size: 14px;
  color: #212529;
  text-decoration: none solid rgb(33, 37, 41);
  line-height: 1rem;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  display: flex;
}

@media only screen and (max-width: 700px) {
  .dropdown-item {
    font-size: 5vw;
    padding: 0.5vh 2vw 0.5vh 2vw !important;
  }
}
