.amount {
  padding-right: 2% !important;
}
.icons {
  width: 50px;
}
.width10 {
  width: 10%;
}
.width15 {
  width: 15%;
}

.employeeVendorList .submittedCell {
  background-color: transparent !important;
}
