.invoiceSummaryHeader {
  padding-top: 2vh;
  text-align: center;
  width: 100%;
  font-size: calc(100% + 1.3vw);
}

.invoiceSummaryPaidDate,
.invoiceSummaryDeletedDate,
.InvoiceTotal {
  padding-top: 1vh;
  text-align: center;
  width: 100%;
  font-size: calc(70% + 1vw);
}
.invoiceSummaryContainer {
  max-width: 1140px;
  margin-bottom: 3vh;
}

.returnButtonDiv {
  text-align: right;
  width: 95%;
}

.printButtonDiv {
  display: inline-block;
  text-align: right;
  margin-right: 2%;
}

.invoiceSummaryRow {
  margin-right: 0;
}

.summaryLabel {
  text-align: right;
  padding-right: 0;
  padding-left: 0;
  font-weight: bolder;
}
.referRateSheetLabel {
  text-align: left;
  padding-right: 0;
  padding-left: 0;
  font-weight: bolder;
}

.InvoiceSummaryLineItems {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.invoiceSummaryDetailsContainer {
  margin-top: 3vh;
  max-width: none;
}

.summaryField {
  padding-right: 0;
  padding-left: 10px;
  overflow-wrap: break-word;
}

.InvoiceSummaryLineItemTable td,
.InvoiceSummaryLineItemTable th,
.InvoiceSummaryPhotoTable td,
.InvoiceSummaryPhotoTable th {
  padding: 0;
}

@media only screen and (max-width: 700px) {
  .invoiceSummaryDetailsContainer {
    font-size: calc(50% + 1vw);
  }

  #printButton,
  #returnButton,
  #towerInvoiceSubsequentButton {
    font-size: calc(40% + 1vw);
  }
  #InvoiceSummaryLineItemTableMobile td,
  .InvoiceSummaryPhotoTable td {
    border: none;
    position: relative;
    padding-left: 5%;
    white-space: normal;
    text-align: left;
    vertical-align: middle;
  }

  #InvoiceSummaryLineItemTableMobile tr > tr:nth-of-type(odd),
  .InvoiceSummaryPhotoTable td > tr:nth-of-type(odd) {
    background: none;
  }
  .InvoiceSummaryPhotoTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #imageTableMobile tbody tr {
    background: none;
  }
}
