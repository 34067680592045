.SubmitInvoiceConfirmationContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.SubmitInvoiceConfirmationHeading {
  position: relative;
  padding-top: 10vh;
  font-style: normal;
  font-weight: normal;
  font-size: calc(100% + 0.8vw);
  line-height: 8vh;
  text-align: center;
  color: #000000;
}

.SubmitInvoiceConfirmationMessage {
  position: relative;
  padding-top: 1vh;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  color: #000000;
}

.SubmitInvoiceDiv {
  padding-top: 10vh;
  max-width: 400px;
  position: relative;
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 5vh; /*Stops footer from overlapping*/
}

#SubmitInvoiceConfirmationButton {
  position: relative;
  max-width: 200px;
  width: 25vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
