.markerLabel {
  font-weight: bold;
}
.mapOverElements {
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 10;
}

div#marker-example {
  width: 100% !important;
  height: 400px !important;
  position: relative !important;
  overflow: hidden !important;
}

.divFontLocation {
  font-size: 60%;
}

.vendorListAddress {
  font-weight: bold;
}
