.created-on-column {
    width:17%;
}

.title-column {
    width: 40%;
}

.url-column {
    width: 35%;
}

.actions-column {
    width: 5%;
}