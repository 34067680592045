.noPadding {
  padding: 0 0 0 0 !important;
}
.vendorPadding {
  padding-left: 50px !important;
}

.changesInlineDiv {
  display: inline;
}

.changesHeaderDiv {
  display: inline;
  font-weight: bolder;
}
