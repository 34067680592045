input[type="file"].file {
    cursor: pointer;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    font-size: 200px;
    opacity: 0;
    visibility: visible;
}

.inputWrapper {
    height: 50px;
    width: 100%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border: 1px dashed;
    border-radius: 4px;
    background-color: #fdfdfd;
    text-align: center;
    padding-top: 10px;
    font-size: 16px;
}

.fileWrapper {
    width: 100%;
}