.CustomTableContainer {
  padding-bottom: 8%;
  padding-top: 2%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.CustomTableContainer .table {
  table-layout: fixed !important;
  white-space: pre-line;
}

.tableHeader {
  text-align: center;
  font-size: 45px;
  padding: 1vh 0 25px 0;
}

.stickyHeader thead th {
  position: sticky;
  top: 120px;
  z-index: 999;
  background-color: #fff;
}

.searchBar {
  position: sticky;
  top: 71px;
  z-index: 999;
  width: 100%;
  background-color: #fff;
}

.CustomTableWrapper,
.SearchText {
  font-size: min(3.5vw, 18px);
  margin: 0px !important;
}

.search-label {
  padding: 1vh 0 1vh 0;
}

.dropdown-menu {
  bottom: 100%;
  top: auto;
}
.CustomTableContainer .dropdown-menu {
  z-index: 100;
}

.CustomTableContainer .cellStyle {
  word-wrap: break-word;
}

.CustomTableContainer .cellStyle td {
  vertical-align: middle;
}

.CustomTableContainer .cellStyle .expand-cell,
.expand-cell-header {
  word-wrap: normal;
}

.CustomTableContainer .buttonPadding {
  padding: 8px 0 8px 0;
}

.buttonWrapper {
  font-size: 140%;
  margin: 0 6px 0 6px;
  cursor: pointer;
}
.buttonCellWrapper {
  display: flex;
  padding: 0 4px 0 4px;
  color: dimgray;
}

.row-expansion-style .buttonCellWrapper {
  justify-content: space-evenly;
}

.row-expansion-style span {
  width: 100%;
  padding-left: 50%;
  text-align: right;
  justify-content: right;
  background-color: blue;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.width40 {
  width: 40%;
}
.width30 {
  width: 30%;
}
.width20 {
  width: 20%;
}
.width10 {
  width: 10%;
}

@media only screen and (max-width: 768px) {
  .CustomTableContainer .btn {
    font-size: calc(12px + 0.5vw);
  }
  .tableHeader {
    font-size: 25px;
    padding-bottom: 10px;
  }
}
