.footer {
	font-size: min(4vw, 20px);
}
.footerNavBar {
	display: flex;
	align-items: flex-start;
	align-content: flex-start;
	justify-content: flex-start;
	max-width: none;
	flex-wrap: wrap;
}

.footerBreak {
	flex-basis: 100%;
}

.footerNotLoggedIn {
	font-size: min(4vw, 20px);
	position: absolute !important;
	bottom: 0 !important;
	left: 0 !important;
	right: 0 !important;
}

.footerRightDiv {
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;
	margin-left: 5%;
}
