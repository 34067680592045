.bulletin-container {
    position: sticky;
    bottom: "15px";
    background-color: "#ffffffc8";
    margin: "5% 10%",
}

.form-row {
    margin-bottom: 20px;
    font-size: 18px;
}

.bulletin-form {
    font-size: 1rem;
}