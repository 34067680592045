.lineItemUnit,
.lineItemtotal {
	width: 3em;
	height: 1.5em;
	display: inline-block;
}

.lineItemCounterButton {
	display: inline-block;
	padding: 0 0.25em;
}

.OtherTotalDollarSpan {
	padding-right: 3.5%;
	display: inline-flex;
}
.OtherLineItemUnit {
	width: 3em;
	height: 1.5em;
	display: inline;
}
.OtherLineItemTotal {
	width: auto;
	height: 1.5em;
	display: inline-block;
	padding: 0.375rem 0.75rem;
	line-height: 1.5;
	border: 1px solid #343a40;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.lineItemTotalMobile,
.lineItemUnitDate {
	width: 18em;
	height: 1.5em;
}

.dateUnitRate {
	width: 16em;
	height: 1.5em;
}

.rateSheetLink {
  font-size: min(4vw, 20px) !important;
  text-decoration: underline;
  border: blue;
}
.otherInput {
	width: 4em !important;
	height: 1.5em !important;
}

.lineItemOtherDescription {
	height: 1.5em;
	font-size: 100% !important;
}

.lineItemUnitFlat {
	width: 3em;
	height: 1.5em;
	border: none;
	background: transparent !important;
}

.lineItemLabel {
	padding-left: 6px;
}

.right {
	text-align: right;
}
