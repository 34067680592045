.reasonForDeletionTitle {
  display: block;
  text-align: center;
  text-decoration: underline;
  font-weight: bold;
}

.reasonForDeletion {
  display: block;
  text-align: center;
  font-style: italic;
}
