.StorageListContainer {
	padding-bottom: 8%;
	padding-top: 2%;
	margin-left: auto;
	margin-right: auto;
	max-width: 1500px;
	font-size: smaller;
}

.csvStorageButton {
  max-width: 350px !important;
  min-width: 200px !important;
  width: 18vw;
  font-size: min(3.5vw, 18px);
}

.csvStorageSpinner {
  align-content: center;
}
