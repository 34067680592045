.amount {
  padding-right: 2% !important;
}
.icons {
  width: 50px;
}
.width10 {
  width: 10%;
}

.employeeInvoiceList .submittedCell {
  background-color: transparent !important;
}

.review-row {
  background-color: #F9E79F !important;
}