.alert .alertMessage {
  margin-top: auto;
  margin-bottom: auto;
  max-width: 100%;
  width: "auto";
  text-align: left;
  font-style: italic;
}

.alert .alertContainer {
  height: 100%;
  width: 100%;
}

.alert .alertContainer .alertContainerRow .alertIcon {
  min-width: 4rem;
  max-width: 8rem;
}

.alert {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 480px) {
  .alertIcon {
    display: none;
  }
}
