.annotationContainer {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}

.annotationAlert {
	background-color: var(--actionRequired-light-color) !important;
	color: inherit;
	border-color: inherit;
}

.annotationContainer .col,
.annotationContainer .col-3 {
	margin: min(2vw, 5px);
}

.annotationTitle {
	display: block;
	text-align: center;
	text-decoration: underline;
	font-weight: bold;
}

.annotationMessage {
	display: block;
	text-align: center;
	font-style: italic;
}

.annotationValue {
	display: block;
	text-align: center;
	font-style: italic;
}

.annotationDecision {
	display: block;
	text-align: center;
	font-style: italic;
	padding-top: 15px;
}

.rejectAnnotationButton {
	padding: 0.2rem 0.4rem;
	font-size: 1.25rem;
	line-height: 1;
	position: absolute;
	bottom: 0;
	left: 0;
}

.annotationIcon {
	min-width: 4rem;
	max-width: 8rem;
}

@media only screen and (max-width: 800px) {
	.annotationIcon {
		display: none;
	}

	.rejectAnnotationButton {
		padding: 2vw;
		margin: 1vw;
		font-size: 4vw !important;
		position: relative;
	}
}
