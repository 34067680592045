.lineItemUnitFlat {
  font-size: 100% !important;
  width: 30%;
}

#SpecialItemTable {
  margin-top: 3%;
  margin-bottom: 0px;
}

@media only screen and (max-width: 760px) {
  #SpecialItemTable td {
    border: none;
    position: relative;
    padding-left: 5%;
    white-space: normal;
    text-align: left;
    font-size: calc(12px + 1vw);
  }
  #SpecialItemTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #SpecialItemTable td > tr:nth-of-type(odd) {
    background: none;
  }
  #SpecialItemTable input {
    font-size: 100% !important;
    padding-top: 0%;
    padding-bottom: 0%;
  }
  .invoiceLinesContainer #alertError,
  .popover-body,
  #AddSpecialItem,
  #selectSpecialLineItem {
    font-size: calc(12px + 1vw);
  }

  .invoiceLinesContainer h5 {
    font-size: calc(100% + 1vw);
  }
}
