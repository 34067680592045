.csvButton {
  max-width: 350px !important;
  min-width: 200px !important;
  width: 18vw;
  font-size: min(3.5vw, 18px);
}

.csvSpinner {
  align-content: center;
}

.DateRangePicker_picker {
  z-index: 1000 !important;
}

.DateInput #endDate, .DateInput #startDate {
  font-weight: 400;
  font-size: 16px;
  padding: 5px 15px;
}

.DateRangePicker, .DateRangePickerInput__withBorder {
  max-width: 350px !important;
  min-width: 200px !important;
  width: 18vw;
  margin-bottom: 5px;
  border-radius: 0.25rem!important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.DateRangePicker .DateInput {
  width: 46%;
}

.DateRangePicker .DateInput #endDate {
  text-align: right;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background: #007BFF !important;
  border: none !important;
  color: #FFF !important;
}

.CalendarDay__selected_span {
  background: rgba(0, 123, 255, 0.3) !important;
  border: none !important;
  color: #212529 !important;
}

.CalendarDay__today {
  background: none !important;
  border: 2px solid #007BFF !important;
  color: #000 !important;
}

.CalendarDay__selected_span:hover {
  background: rgba(0, 123, 255, 0.6) !important;
  color: #000 !important;
}