.annotationTextCol {
	width: 95%;
	padding-right: 2% !important;
}

.annotationTextColTable {
	width: 100%;
}
.annotatedValueCol {
	padding-right: 2% !important;
}
.annotationInlineDiv {
	display: inline;
}

.annotationHeaderDiv {
	display: inline;
	font-weight: bolder;
}
.employeeAnnotationPadding {
	padding-left: 25px !important;
}

.form-control {
	font-size: 100%;
}
.vendorReplyPadding {
	padding-left: 25px !important;
}

.photoHeaderDiv {
	width: 100%;
}
.photoTextDiv {
	display: inline-block;
	text-align: left;
	width: 25%;
}
.photoButtonDiv {
	display: inline-block;
	text-align: right;
	width: 75%;
}

.summaryLabel {
	text-align: right;
	padding-right: 0;
	padding-left: 0;
	font-weight: bolder;
}
.referRateSheetTable {
	margin-bottom: 0;
}
.referRateSheetLabel {
	text-align: left;
	padding-right: 0;
	padding-left: 0;
	font-weight: bolder;
}

.InvoiceSummaryLineItems,
.InvoiceSummaryAnnotations {
	padding-top: 8px;
	margin-left: auto;
	margin-right: auto;
	width: 90%;
}

.invoiceSummaryDetailsContainer {
	max-width: none;
}

.summaryField {
	padding-right: 0;
	padding-left: 10px;
}

.InvoiceSummaryLineItemTable td,
.InvoiceSummaryLineItemTable th,
.InvoiceSummaryAnnotationsTable td,
.InvoiceSummaryAnnotationsTable th {
	padding: 0;
}

.InvoiceSummaryLineItemTable2 td,
.InvoiceSummaryLineItemTable2 th {
	padding: 0;
}

.InvoiceSummaryLineItemTable2 {
	margin-left: auto;
	width: 90%;
}

.InvoiceTotal {
	width: 100%;
	font-size: calc(70% + 1vw);
}

.invoiceSummaryAnnotationsLineItem {
	width: 25%;
}

.additionalInfoButton {
	border: none !important;
}

.infoTooltip > .tooltip-inner {
	/* width: 600px !important; */
	background-color: lightgray;
	color: #000;
	border: 1px solid #062e56;
	max-width: 60%;
	text-align: left;
	pointer-events: auto !important;
	overflow-y: scroll;
	font-size: medium;
}

.infoPopover {
	/* width: 600px !important; */
	background-color: white;
	color: #000;
	border: 1px solid #062e56;
	max-width: 60%;
	max-height: 70%;
	text-align: left;
	/* pointer-events: auto !important; */
	/* overflow-y: scroll; */
	overflow-y: auto;
	font-size: medium;
}

#claimEditButton {
	padding: 0rem 0rem;
	font-size: 1.2rem;
	line-height: 0;
}

#vehiclePlateEditButton {
	padding: 0rem 0rem;
	font-size: 1.2rem;
	line-height: 0;
}

#vehicleVinEditButton {
	padding: 0rem 0rem;
	font-size: 1.2rem;
}
#decodedVehicleInfoContainer {
	font-size: 80%;
}
.vehicleInfoheader {
	text-align: center;
	padding-bottom: 1.2rem;
	font-weight: bolder;
}
.borderDiv,
.borderBottomDiv {
	border-top: 1px solid black;
	margin-left: 100px;
	margin-top: 20px;
	margin-bottom: -12px;
	position: relative;
	margin-right: 50px;
}

.workItemHeaderWidth {
	min-width: 40%;
	max-width: 50%;
	width: 45%;
}

@media only screen and (max-width: 700px) {
	#claimEditButton {
		padding: 0rem 0rem 0rem 0rem;
		font-size: calc(50% + 1.7vw);
		line-height: 0;
		font-weight: lighter;
	}

	#vehiclePlateEditButton {
		padding: 0rem 0rem 0rem 0rem;
		font-size: calc(50% + 1.7vw);
		line-height: 0;
		font-weight: lighter;
	}

	.borderDiv,
	.borderBottomDiv {
		border-top: 1px solid white;
	}
}
@media print {
	.editIcon {
		display: none;
	}
}
