:root {
	--submitted-color: #17a2b8;
	--paid-color: #28a745;
	--draft-color: #6c757d;
	--actionRequired-color: #ffcea7;
	--deleted-color: #ff02028f;

	--submitted-light-color: #d3f9ff;
	--paid-light-color: #d6ffe0;
	--draft-light-color: #f1f1f1;
	--actionRequired-light-color: #ffd7b7;
	--deleted-light-color: #ffa7a78f;
}

.StatusAlert {
	margin-bottom: 0px;
	text-align: center;
	font-weight: 600;
}
