input[type="file"] {
  visibility: hidden;
  position: absolute;
}

.thumbnailWrapper {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  align-content: center;
}

.thumbnailImage {
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  max-height: 100px;
  height: auto;
  max-width: 100px;
  width: auto;
  border: none;
}

.itemName {
  word-wrap: break-word;
}

.maxWidth600 {
  max-width: 600px;
}

.imageUploadContainer .fa-images {
  font-size: min(5vw, 40px);
}

.imageTable td > tr:nth-of-type(odd) {
  background: none;
}

@media only screen and (max-width: 700px) {
  .imageTable {
    font-size: 4vw;
  }
  #imageTableMobile td,
  #imageTableMobile th {
    padding: 0;
  }
}
