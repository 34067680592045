.dashboardContainer {
	padding-bottom: 65px;
	padding-top: 3%;
	width: 100%;
	align-content: left;
	align-items: left;
	align-self: left;
	text-align: left;
	max-width: 2300px;
}

.title {
	text-align: left;
}
.customCreateButton {
	font-size: 13.95px;
	padding: 6px 12px;
	margin-bottom: 9.6px;
	margin-left: 2%;
	font-weight: normal !important;
	width: 48%;
	background-color: #007bff;
	border-color: #007bff;
	color: white;
}
.invoiceButton {
	font-size: 13.95px;
	padding: 6px 12px;
	margin-bottom: 9.6px;
	margin-right: 2%;
	font-weight: normal !important;
	width: 48%;
}
.createButtonDashboardDiv {
	text-align: left;
}
.widgetsContainer {
	flex-wrap: wrap;
	margin: auto;
	max-width: 2300px;
}
.greeting {
	font-weight: bold;
	color: darkslategray;
}
.description {
	color: gray;
}
.dashboardDropdown > .btn-outline-primary {
	color: gray;
	border-color: gray;
	font-weight: bold;
}
.dashboardDropdown > .form-control:hover {
	color: #fff;
	background-color: gray;
	border-color: gray;
}
.dashboardDropdown {
	color: gray;
	width: fit-content;
	font-size: x-large;
}
.dashboardDropdown > option {
	background-color: white;
	color: black;
}
.dashboardDropdown > .show > .form-control:focus {
	color: #fff;
	background-color: gray;
	border-color: gray;
}
.Widget {
	/* background-color: rgb(248, 248, 248); */
	border: rgb(175, 175, 175);
	margin: 0.3%;
	border-width: 1px;
	border-style: solid;
	border-radius: 15px;
	width: 100%;
}

.Widget:hover {
	cursor: pointer;
}
.pieChartWidget {
	padding: 1% 0 1% 0;
	width: 49.3%;
	min-width: 280px;
}

.tableWidget {
	min-width: 280px;
	font-size: calc(1.5vw);
	border: 1px solid rgb(175, 175, 175);
}

.singleValueWidget {
	padding: 1.8% 0 1% 0;
	width: 32.7%;
}

.TowerDashboardHeader {
	align-content: center;
	align-items: center;
	text-align: center;
}

.actionRequiredWidget {
	background-color: #d1ecf1;
	border-color: #529daa;
	border-width: 4px !important;
}

.paidWidget {
	background-color: #ceeabd;
}

.dashboardContainer h6 {
	font-size: 1.1rem;
}

.dashboardContainer .row {
	margin-left: -1%;
	margin-right: -1%;
}
@media only screen and (max-width: 700px) {
	.tableWidget {
		font-size: 50%;
	}
	.tableWidget td {
		padding: 1%;
	}
}
.col {
	order: 1;
}
@media only screen and (min-width: 960px) {
	.tableWidget {
		width: 100%;
	}
}

@media only screen and (min-width: 540px) {
	.Widget {
		height: fit-content;
		width: 100%;
	}
	.col {
		width: 100%;
	}
}
@media only screen and (min-width: 0px) and (max-width: 960px) {
	.tableCol {
		order: 0;
		width: 100%;
	}
}
@media only screen and (min-width: 960px) {
	.tableCol {
		order: 1;
	}
	.col {
		order: 0;
	}
}
@media only screen and (min-width: 1500px) {
	.tableCol {
		order: 1;
		width: 40%;
	}
	.col {
		order: 0;
	}
}
